import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		redirect: '/login',
	},
	{
		name: "login",
		path: "/login",
		component: () => import('../views/login/index.vue'),
	},
	{
		name: 'index',
		path: '/index',
		component: () => import('../views/index.vue'),
		children: [{
				path: '/dataAnalysis',
				name: 'dataAnalysis',
				component: () => import('../views/dataAnalysis/index.vue')
			},
			{
				path: '/warehouseClassify',
				name: 'warehouseClassify',
				component: () => import('../views/warehouse/warehouseClassify.vue')
			},
			{
				path: '/warehouseData',
				name: 'warehouseData',
				component: () => import('../views/warehouse/warehouseData.vue')
			},
			{
				path: '/commodityClassify',
				name: 'commodityClassify',
				component: () => import('../views/commodity/commodityClassify.vue')
			},
			{
				path: '/commodityData',
				name: 'commodityData',
				component: () => import('../views/commodity/commodityData.vue')
			},
			{
				path: '/goodsTranslateLst',
				name: 'goodsTranslateLst',
				component: () => import('../views/commodity/goodsTranslateLst.vue')
			},
			{
				path: '/stock',
				name: 'stock',
				component: () => import('../views/commodity/stock.vue')
			},
			{
				path: '/goodsStock',
				name: 'goodsStock',
				component: () => import('../views/commodity/goodsStock.vue')
			},
			{
				path: '/supplierManage',
				name: 'supplierManage',
				component: () => import('../views/supplier/supplierManage.vue')
			},
			{
				path: '/deliveryRoute',
				name: 'deliveryRoute',
				component: () => import('../views/driverRoute/deliveryRoute.vue')
			},
			{
				path: '/driverManagement',
				name: 'driverManagement',
				component: () => import('../views/driverRoute/driverManagement.vue')
			},
			{
				path: '/customerGroup',
				name: 'customerGroup',
				component: () => import('../views/customer/customerGroup.vue')
			},
			{
				path: '/customerData',
				name: 'customerData',
				component: () => import('../views/customer/customerData.vue')
			},
			{
				path: '/blacklist',
				name: 'blacklist',
				component: () => import('../views/customer/blacklist.vue')
			},
			{
				path: '/cooperationApply',
				name: 'cooperationApply',
				component: () => import('../views/customer/cooperationApply.vue')
			},
			{
				path: '/salesBilling',
				name: 'salesBilling',
				component: () => import('../views/salesManagement/salesBilling.vue')
			},
			{
				path: '/orderList',
				name: 'orderList',
				component: () => import('../views/salesManagement/orderList.vue')
			},
			{
				path: '/orderListXyf',
				name: 'orderListXyf',
				component: () => import('../views/salesManagement/orderListXyf.vue')
			},
			{
				path: '/orderListAddress',
				name: 'orderListAddress',
				component: () => import('../views/salesManagement/orderListAddress.vue')
			},
			{
				path: '/afterSales',
				name: 'afterSales',
				component: () => import('../views/salesManagement/afterSales.vue')
			},
			{
				path: '/salesSummary',
				name: 'salesSummary',
				component: () => import('../views/salesManagement/salesSummary.vue')
			},
			{
				path: '/salesSummaryLeader',
				name: 'salesSummaryLeader',
				component: () => import('../views/salesManagement/salesSummaryLeader.vue')
			},
			{
				path: '/salesSummaryNoDelivery',
				name: 'salesSummaryNoDelivery',
				component: () => import('../views/salesManagement/salesSummaryNoDelivery.vue')
			},
			{
				path: '/salesSummaryPayType',
				name: 'salesSummaryPayType',
				component: () => import('../views/salesManagement/salesSummaryPayType.vue')
			},
			{
				path: '/preDeliveryNote',
				name: 'preDeliveryNote',
				component: () => import('../views/preDelivery/preDeliveryNote.vue')
			},
			{
				path: '/preDeliveryNoteAddress',
				name: 'preDeliveryNoteAddress',
				component: () => import('../views/preDelivery/preDeliveryNoteAddress.vue')
			},
			{
				path: '/preDeliveryNoteGoods',
				name: 'preDeliveryNoteGoods',
				component: () => import('../views/preDelivery/preDeliveryNoteGoods.vue')
			},
			{
				path: '/preDeliverySummary',
				name: 'preDeliverySummary',
				component: () => import('../views/preDelivery/preDeliverySummary.vue')
			},
			{
				path: '/preDeliverySummaryDay',
				name: 'preDeliverySummaryDay',
				component: () => import('../views/preDelivery/preDeliverySummaryDay.vue')
			},
			{
				path: '/preDeliveryCheck',
				name: 'preDeliveryCheck',
				component: () => import('../views/preDelivery/preDeliveryCheck.vue')
			},
			{
				path: '/statementLst',
				name: 'statementLst',
				component: () => import('../views/preDelivery/statementLst.vue')
			},
			{
				path: '/restDaySet',
				name: 'restDaySet',
				component: () => import('../views/preDelivery/restDaySet.vue')
			},
			{
				path: '/warehouseManagement',
				name: 'warehouseManagement',
				component: () => import('../views/rkd/warehouseManagement.vue')
			},
			{
				path: '/warehouseReceipt',
				name: 'warehouseReceipt',
				component: () => import('../views/rkd/warehouseReceipt.vue')
			},
			{
				path: '/warehouseSummary',
				name: 'warehouseSummary',
				component: () => import('../views/rkd/warehouseSummary.vue')
			},
			{
				path: '/tkdLst',
				name: 'tkdLst',
				component: () => import('../views/tkd/tkdLst.vue')
			},
			{
				path: '/tkdAdd',
				name: 'tkdAdd',
				component: () => import('../views/tkd/tkdAdd.vue')
			},
			{
				path: '/tkdSummary',
				name: 'tkdSummary',
				component: () => import('../views/tkd/tkdSummary.vue')
			},
			//盘亏
			{
				path: '/warehousePankuiManagement',
				name: 'warehousePankuiManagement',
				component: () => import('../views/pankui/warehousePankuiManagement.vue')
			},
			{
				path: '/warehousePankuiReceipt',
				name: 'warehousePankuiReceipt',
				component: () => import('../views/pankui/warehousePankuiReceipt.vue')
			},
			{
				path: '/warehousePankuiSummary',
				name: 'warehousePankuiSummary',
				component: () => import('../views/pankui/warehousePankuiSummary.vue')
			},
			//盘盈
			{
				path: '/warehousePanyingManagement',
				name: 'warehousePanyingManagement',
				component: () => import('../views/panying/warehousePanyingManagement.vue')
			},
			{
				path: '/warehousePanyingReceipt',
				name: 'warehousePanyingReceipt',
				component: () => import('../views/panying/warehousePanyingReceipt.vue')
			},
			{
				path: '/warehousePanyingSummary',
				name: 'warehousePanyingSummary',
				component: () => import('../views/panying/warehousePanyingSummary.vue')
			},
			{
				path: '/dayIncomeSummary',
				name: 'dayIncomeSummary',
				component: () => import('../views/financeManage/dayIncomeSummary.vue')
			},
			{
				path: '/monthIncomeSummary',
				name: 'monthIncomeSummary',
				component: () => import('../views/financeManage/monthIncomeSummary.vue')
			},
			{
				path: '/dayFeeSummary',
				name: 'dayFeeSummary',
				component: () => import('../views/financeManage/dayFeeSummary.vue')
			},
			{
				path: '/monthFeeSummary',
				name: 'monthFeeSummary',
				component: () => import('../views/financeManage/monthFeeSummary.vue')
			},
			{
				path: '/incomeTypeLst',
				name: 'incomeTypeLst',
				component: () => import('../views/financeManage/incomeTypeLst.vue')
			},
			{
				path: '/incomeLst',
				name: 'incomeLst',
				component: () => import('../views/financeManage/incomeLst.vue')
			},
			{
				path: '/feeTypeLst',
				name: 'feeTypeLst',
				component: () => import('../views/financeManage/feeTypeLst.vue')
			},
			{
				path: '/feeLst',
				name: 'feeLst',
				component: () => import('../views/financeManage/feeLst.vue')
			},
			{
				path: '/financeReport',
				name: 'financeReport',
				component: () => import('../views/financeManage/report.vue')
			},
			{
				path: '/cutTimeSet',
				name: 'cutTimeSet',
				component: () => import('../views/salesManagement/cutTimeSet.vue')
			},
			{
				path: '/groupManage',
				name: 'groupManage',
				component: () => import('../views/wx/groupManage.vue')
			},
			{
				path: '/scheduledPush',
				name: 'scheduledPush',
				component: () => import('../views/wx/scheduledPush.vue')
			},
			{
				path: '/push',
				name: 'push',
				component: () => import('../views/wx/push.vue')
			},
			{
				path: '/messageLst',
				name: 'messageLst',
				component: () => import('../views/wx/messageLst.vue')
			},
			{
				path: '/wxsetting',
				name: 'wxsetting',
				component: () => import('../views/wx/setting.vue')
			},
			{
				path: '/baseSetting',
				name: 'baseSetting',
				component: () => import('../views/setting/index.vue')
			},
			{
				path: '/bannerSet',
				name: 'bannerSet',
				component: () => import('../views/setting/homeSet/bannerSet.vue')
			},
			{
				path: '/noticeSet',
				name: 'noticeSet',
				component: () => import('../views/setting/homeSet/noticeSet.vue')
			},
			{
				path: '/jielongSet',
				name: 'jielongSet',
				component: () => import('../views/setting/homeSet/jielongSet.vue')
			},
			{
				path: '/goodsSet',
				name: 'goodsSet',
				component: () => import('../views/setting/homeSet/goodsSet.vue')
			},
			{
				path: '/searchSet',
				name: 'searchSet',
				component: () => import('../views/setting/homeSet/searchSet.vue')
			},
			{
				path: '/styleSet',
				name: 'styleSet',
				component: () => import('../views/setting/homeSet/styleSet.vue')
			},
			{
				path: '/deliveryPoints',
				name: 'deliveryPoints',
				component: () => import('../views/delivery/points.vue')
			},
			{
				path: '/deliveryRoutesPlan',
				name: 'deliveryRoutesPlan',
				component: () => import('../views/delivery/routes.vue')
			},
			{
				path: '/deliveryRoutesPlanWarehouse',
				name: 'deliveryRoutesPlanWarehouse',
				component: () => import('../views/delivery/routes_warehouse.vue')
			},
			{
				path: '/userLst',
				name: 'userLst',
				component: () => import('../views/users/list.vue')
			},
			{
				path: '/walletLogLst',
				name: 'walletLogLst',
				component: () => import('../views/financeManage/moneyLst.vue')
			},
			{
				path: '/bankCard',
				name: 'bankCard',
				component: () => import('../views/financeManage/bankCard.vue')
			},
			{
				path: '/deviceSet',
				name: 'deviceSet',
				component: () => import('../views/setting/deviceSet.vue')
			},
			{
				path: '/codeLst',
				name: 'codeLst',
				component: () => import('../views/codePlate/codeLst.vue')
			},
			{
				path: '/transLst',
				name: 'transLst',
				component: () => import('../views/codePlate/transLst.vue')
			},
			{
				path: '/operateLogLst',
				name: 'operateLogLst',
				component: () => import('../views/log/lst.vue')
			}
		]
	},
]

const router = new VueRouter({
	routes
})

export default router