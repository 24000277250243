import axios from 'axios' // 引入axios
import CryptoJS from 'crypto-js'
var timestamp = new Date().getTime()
// 环境的切换
if (process.env.NODE_ENV === 'development') {
	axios.defaults.baseURL = '/api' // 开发环境   配置跨域了
} else if (process.env.NODE_ENV === 'debug') {
	axios.defaults.baseURL = '' // 调试环境
} else if (process.env.NODE_ENV === 'production') {
	axios.defaults.baseURL = 'https://tuanhuo.howtec.cc' // 生产环境
}

// 请求超时时间
axios.defaults.timeout = 40000;
// axios.defaults.headers['Content-Type'] = 'application/json';
// 请求拦截器
axios.interceptors.request.use(config => {
	config.headers.token = sessionStorage.getItem('token')
	config.headers.sign = CryptoJS.MD5(sessionStorage.getItem('token') +
		'w2MDYDE8nx4CQdA8fn2Nmnexn4bSyBbh' + timestamp).toString()
	config.headers.timestamp = timestamp
	return config
}, error => {
	return Promise.reject(error);
})

// 响应拦截器
// axios.interceptors.response.use(
//   // 服务码是200的情况
//   response => {
//     // console.log("这是返回",response.data.success)
//     if (response.status === 200) {
//         switch (response.data.success) {
//             // 未登录
//             case false:
//             Message({
//                 message: response.data.message,
//                 type: 'error',
//                 offset:380,
//                 duration:1000
//             });
//             break;
//         }
//         return Promise.resolve(response);
//     } else {
//         return Promise.reject(response)
//     }
//   },
//   // 服务器状态码不是200的情况
//   error => {
//     if (error.response.status) {
//       switch (error.response.status) {
//         // 404请求不存在
//         case 404:
//           Message({
//             message: '网络请求不存在',
//             type: 'error',
//             offset:380,
//             duration:1000
//           });
//           break;
//         // 其他错误，直接抛出错误提示
//         default:
//           Message({
//             message: error.response.data.message,
//             type: 'error',
//             offset:380,
//             duration:1000
//           });
//       }
//       return Promise.reject(error.response)
//     }
//   }
// )
export {
	axios
}